import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Mission' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        ICTrust (<i><strong>I see Trust</strong></i> ou <i><strong>Information Communication Technologies based on Trust</strong></i>) a pour objectif de donner un éclairage des différents aspects de la cybersécurité et de la confidentialité des données.
       {/** Nous cherchons à démystifier et à mettre en lumière les aspects les plus importants de la cybersécurité pour une mieux se protéger. */}
      </Text>
    </Card>
  </Section>
)

export default Commitment
